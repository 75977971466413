import { SIDEBARSHOW } from '../Actions/actionTypes';

const initialState = 'responsive';

export const sideBarShown = (state = initialState, action) => {
  // console.log("Actions payload", action)
  switch (action.type) {
    case SIDEBARSHOW:
      return (state = action.payLoad);
    default:
      return state;
  }
};

import { ADDHSEUSER } from '../Actions/actionTypes';

const initialState = {
  addHseUser: [],
};

const addHseUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDHSEUSER:
      console.log('Reducer : AddHseUSer', action);
      return {
        addHseUser: (state.addHseUser = action.payload),
        ...state,
      };

    default:
      return state;
  }
};

export default addHseUserReducer;

import { REPORTNUMBER } from '../Actions/actionTypes';

const initialState = {
  reportNumber: {},
  incidentReportNumber:{},
};

const reportNumberRecucer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTNUMBER:
      console.log('Reducer : AddReport Number', action);
      return {
        ...state,
        reportNumber: (state.reportNumber = action.payload),
      };

    default:
      return state;
  }
};

export default reportNumberRecucer;

import { ACTIVETAB } from '../Actions/actionTypes';

const initialState = {
  activeTabName: 'Tab1',
};

const tabNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVETAB:
      console.log('Reducer : AddActive Tab name', action);
      return {
        activeTabName: (state.activeTabName = action.payload),
        ...state,
      };

    default:
      return state;
  }
};

export default tabNameReducer;

import { combineReducers } from 'redux';
import tabNameReducer from './activeTabReducer';
import reportNumberRecucer from './reportNumber';
import { sideBarShown } from './sideBarShowReducer';
import userReducer from './userToken';
import addHseUserReducer from './addHseUserReducer';

export const rootReducer = combineReducers({
  sideBarShown: sideBarShown,
  userReducer: userReducer,
  reportNumberRecucer: reportNumberRecucer,
  tabNameReducer: tabNameReducer,
  addHseUserReducer: addHseUserReducer,
});

import {
  ADDUSERROLE,
  ADDUSERTOKEN,
  DELUSERTOKEN,
  TENANTID,
} from '../Actions/actionTypes';

const initialState = {
  userToken: '',
  userRole: '',
  tenantId: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADDUSERTOKEN:
      console.log('Reducer : AdduserToken', action);
      return {
        userToken: (state.userToken = action.payload),
        ...state,
      };
    case ADDUSERROLE:
      console.log('Reducer : AdduserRole', action);
      return {
        userRole: (state.userRole = action.payload),
        ...state,
      };
    case TENANTID:
      console.log('Reducer : AddTenantId', action);
      return {
        tenantId: (state.tenantId = action.payload),
        ...state,
      };
    case DELUSERTOKEN:
      return {
        userToken: (state.userToken = ''),
        userRole: (state.userRole = ''),
        ...state,
      };

    default:
      return state;
  }
};

export default userReducer;

import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Tree = React.lazy(() => import('./views/pages/Tree/Tree'));
const Graph = React.lazy(() => import('./views/pages/Tree/Graph'));
const Table = React.lazy(() => import('./Table'));
const PDF = React.lazy(() => import('./PDF'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              path="/table"
              name="Table"
              render={props => <Table {...props} />}
            />
            <Route
              path="/pdf"
              name="PDF"
              render={props => <PDF {...props} />}
            />
            <Route
              path="/tree"
              name="Tree"
              render={props => <Tree {...props} />}
            />
            <Route
              path="/graph"
              name="Tree"
              render={props => <Graph {...props} />}
            />
            <Route
              exact
              path="/login"
              name="Login Page"
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={props => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={props => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={props => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={props =>
                localStorage.getItem('token') ? (
                  <TheLayout {...props} />
                ) : (
                  <Redirect to="/login" {...props} />
                )
              }
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
